import Skeleton from "react-loading-skeleton"

const JournalSectionLoading = ({ className = "" }) => {
  return (
    <div className={`${className}`}>
      <div className="row">
        {Array(3)
          .fill()
          .map((_, item) => (
            <div className="col-md-4" key={`${item}-skeleton-journal`}>
              <Skeleton width={361} height={361} />
              <Skeleton className="mt-2" width={361} height={20} />
              <Skeleton className="mt-2" width={200} height={20} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default JournalSectionLoading
