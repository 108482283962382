import { useState, useEffect } from "react"

//Components
import { useScrollAnim } from "components/hooks/hooks"
import CardProduct from "components/global/card-product/card-product"
import ProductSectionLoading from "components/global/loading-skeleton/product-section-loading"
import { AsyncApollo } from "lib/functions"
import { Q_CATALOGS } from "lib/graphql"
import { useRouter } from "next/router"

const HomeFeatured = ({ currency }) => {
  const Router = useRouter()
  const [trigger, anim] = useScrollAnim()
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  //Functions
  const handleGetCatalogs = async () => {
    let { data, errors } = await AsyncApollo({
      query: Q_CATALOGS,
      variables: {
        lang: Router.locale,
        is_featured: 1,
        currency: currency,
        limit: 6,
      },
    })
    if (data?.catalogs?.products?.length > 0) {
      setProducts(data?.catalogs?.products)
      setLoading(null)
    }
  }

  useEffect(() => {
    handleGetCatalogs()
  }, [])
  return (
    <section className="py-main sc-home-featured" ref={trigger}>
      <div className="container">
        <div className={`title-wrapper ${anim(1)}`}>
          <h2 className="title">Featured Products</h2>
        </div>
        {loading ? (
          <div className="featured-list">
            <ProductSectionLoading className="my-4" />
          </div>
        ) : (
          <div className="featured-list row row-md-5">
            {products?.slice(0, 6)?.map((item, i) => {
              return (
                <div className="col-md-4 col-lg-3 col-6" key={i}>
                  <CardProduct
                    className={`${anim(3 + i)}`}
                    product={item}
                    currency={currency}
                    listName="Featured Product"
                    listId="featured_product"
                    position={i + 1}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default HomeFeatured
