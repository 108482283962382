import Skeleton from "react-loading-skeleton"

const ProductSectionLoading = ({ className = "" }) => {
  return (
    <div className={`${className}`}>
      <div className="row">
        {Array(4)
          .fill()
          .map((_, item) => (
            <div
              className="col-6 col-lg-4"
              key={`product-section-loading-${item}`}
            >
              <Skeleton height={416} />
              <div className="mt-2">
                <Skeleton height={10} style={{ width: "100%" }} />
              </div>
              <div className="w-100 d-flex align-items-center justify-content-center">
                <Skeleton className="py-0" height={10} width={220} />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default ProductSectionLoading
