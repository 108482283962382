import React, { useState, useCallback } from "react"

//Utils
import { useDispatch, useSelector } from "react-redux"
import { useRouter } from "next/router"

// Global Components
import { useMousePosition } from "components/anti/cursor/cursor"
import MouseCursor from "components/global/mouse-cursor"

//Components
import HomeCover from "./home-cover"
import FeaturedProducts from "./home-featured"
import HomeJournal from "./home-journal"

const HomePageComponents = ({ currency, ...props }) => {
  //Utils ********************************************************************************* //
  const router = useRouter()
  //Utils ********************************************************************************* //

  //States ******************************************************************************** //
  const { categories } = useSelector((state) => state.general)
  //States ******************************************************************************** //

  //React Operations ********************************************************************** //
  React.useEffect(() => {
    const verify = router?.query?.verify
    if (verify === "1") {
    }
  }, [router])
  //React Operations ********************************************************************** //

  return (
    <>
      <HomeCover {...props} categories={categories} />
      <FeaturedProducts currency={currency} />
      {/* <HomeJournal {...props} /> */}
    </>
  )
}

export default HomePageComponents
